import "vuestic-ui/css";
import "./assets/main.scss";
import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { vuestic } from "./plugins/vuestic";
import FontAwesomeIcon from "./plugins/fontawesome";
import i18n from "./plugins/i18n";
import { createManager } from "@vue-youtube/core";
import Particles from "@tsparticles/vue3";
import { loadFull } from "tsparticles";
import { useUserStore } from "@/stores/user";
import { useOfflineStore } from "@/stores/offline";
import "material-symbols/rounded.css";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueGtag from "vue-gtag-next";
import { VueCookieNext } from "vue-cookie-next";
import {useShortCuts} from '@/composables/useKeyboardShortcuts'
const app = createApp(App);
const pinia = createPinia();
app.component("fa-icon", FontAwesomeIcon);
app.component("qr-code", VueQrcode);
app.use(Particles, {
  init: async (engine) => {
    await loadFull(engine);
  },
});
app.use(createManager());

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(vuestic);

app.use(async()=>{
  //pre download all the data required
  const {syncBasicData} = useOfflineStore();
  await syncBasicData();
  //
  //authenticate and create the user object for future requests
  const {me} = useUserStore();
  me().then(()=>{
    //load application
    app.mount("#app");
  });
  
});
app.use(VueCookieNext);
app.use(VueGtag, {
  property: { id: "GTM-57KLV8R" },
  router,
  isEnabled: false,
});
app.use(useShortCuts());
//app.mount("#app");
document.title = import.meta.env.VITE_TITLE;

